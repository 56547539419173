import { useEffect, useState } from 'react'
import { Slide, ToastContainer, toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'
import { Header } from 'components/Header'
import { useLocation } from 'react-router-dom'

interface Props {
  locale: string
}

interface LocationState {
  email: string
}

export const ForgotPasswordSuccess: React.FC<Props> = ({ locale }) => {
  const { t } = useTranslation('translation', {
    useSuspense: false,
  })

  const [email, setEmail] = useState('')

  const location = useLocation()

  useEffect(() => {
    toast.success(t('forgot_password.email_instructions_sent'))
  }, [])

  useEffect(() => {
    if (
      (location.state as LocationState) &&
      (location.state as LocationState).email
    ) {
      setEmail((location.state as LocationState).email)
    }
  }, [location])
  return (
    <div className="default-container flex flex-col px-5 pt-7">
      <Header color="black" showLogin />
      <div className="no-scrollbar flex h-full w-full flex-col items-center self-center overflow-auto px-7 pb-10 pt-7 lg:w-5/6 xl:w-5/12">
        <h1 className="text-center font-title text-3xl font-bold text-ar-dark-gray">
          {t('forgot_password.success.title')}
        </h1>

        <p className="mt-4 text-center text-base text-ar-dark-gray">
          {t('forgot_password.success.copy')}
        </p>

        <div className="mt-6 rounded-full border border-ar-dark-gray px-6 py-3">
          <p className="font-body text-ar-dark-gray">{email}</p>
        </div>
      </div>
      <ToastContainer position="bottom-center" transition={Slide} />
    </div>
  )
}
