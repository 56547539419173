import React from 'react'
import { ReactComponent as Chevron } from 'icons/chevron.svg'

interface Props {
  text: string
}

export const Question: React.FC<Props> = ({ text }) => {
  return (
    <>
      <div className="flex cursor-pointer justify-between">
        <div className="order-last text-ar-green">
          <img src="/images/arrow_down.png" className="h-5 w-5" />
        </div>
        <p className="text-left font-title text-xs text-black">{text}</p>
      </div>
    </>
  )
}
